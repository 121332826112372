<template>
  <div>
    <div class="select-flight" v-if="inputMode">
      <b-form-group>
        <b-form-select
          v-model="flightTypeSelected"
          :options="flightTypes"
          @input="updateFlightType()"
        ></b-form-select>
      </b-form-group>
    </div>
    <div v-else>
      <label> {{ flightTypeSelected }}</label>
      <b-button
        class="btn-edit"
        title="Edit FlightType"
        size="sm"
        variant="light"
        @click="toInputMode"
        ><i class="uil uil-edit font-18 text-secondary"></i
      ></b-button>
    </div>
  </div>
</template>
<script>
export default {
  name: "flightTypeComponent",
  props: {
    flightData: {
      type: Array,
    },
    flightType: {
      type: String,
    },
    zoneFlightTypes: {
      type: Array,
    },
  },
  data() {
    return {
      flightTypeSelected: null,
      inputMode: true,
      flightTypes: [
        {
          text: "Flight Type",
          value: null,
        },
      ].concat(this.zoneFlightTypes),
    };
  },
  watch: {
    flightData() {
      if (this.flightData && this.flightData.length > 0) {
        this.flightTypeSelected = this.flightData[0].flight_type;
      }
    },
  },
  mounted() {
    this.flightTypeSelected = this.flightType;
  },
  methods: {
    updateFlightType() {
      this.$emit("input", this.flightTypeSelected);
      this.inputMode = false;
    },
    toInputMode() {
      this.inputMode = true;
    },
  },
};
</script>
<style scoped>
.select-flight {
  width: 200px;
}

.btn-edit {
  padding: 0;
  background: none;
  margin-left: 0.5em;
  border: none;
  vertical-align: middle;
}
</style>
