<template>
  <div>
    <b-form-checkbox v-model="deletePassenger" @input="changeState">
    </b-form-checkbox>
  </div>
</template>
<script>
export default {
  data() {
    return {
      deletePassenger: false,
    };
  },
  methods: {
    changeState() {
      this.$emit("input", this.deletePassenger);
    },
  },
};
</script>
