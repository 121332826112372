<template>
  <b-modal
    :id="orderModalId"
    ref="view-order-detail"
    size="xl"
    :title="orderTitle"
    title-calss="font-18"
    :ok-title="okTitleText"
    @ok="submit"
  >
    <div class="order-details-assignment">
      <div class="customer-info row px-0 px-sm-4">
        <div class="customer-info-tab col-12 col-sm-6">
          <div class="customer-name">
            <span>{{ customerName }}</span>
          </div>
          <div class="customer-contact mb-2">
            <i class="uil uil-phone font-16 text-success mr-1"></i
            ><a :href="'tel:' + customerPhone" class="customer-phone mr-2">{{
              customerPhone
            }}</a>
            | <i class="uil uil-envelope font-16 text-success ml-2"></i
            ><a :href="'mailto:' + customerEmail" class="customer-email ml-1">{{
              customerEmail
            }}</a>
          </div>
          <div
            class="flight-pill mb-2"
            :class="{
              classic: flightType === 'Classic',
              prime: flightType === 'Prime',
              xc: flightType === 'XC',
            }"
          >
            <span>{{ quantity }}x</span>
            {{ order ? order.flightType : "" }}
          </div>

          <div class="pickup-date-time mb-2">
            <i class="uil uil-schedule font-18 text-success mr-1"></i
            ><span
              >{{ formatDate(startTime) }}
              <small>{{ formatTime(startTime) }}</small></span
            >
          </div>
          <div class="pickup-loc mb-2 mb-sm-0">
            <i class="uil uil-location-point font-18 text-success mr-1"></i
            ><span>{{ pickupLoc }}</span>
          </div>
          <div class="alert alert-warning" role="alert" v-if="reviewMessage">
            Change status: {{ reviewMessage }}
          </div>
        </div>
        <div class="payment-info-tab col-12 col-sm-6">
          <b-button
            v-b-toggle.collapse-1
            variant="light"
            size="sm"
            class="d-sm-none"
            ><i class="uil uil-bill font-18 text-secondary mr-1"></i>Payment
            Info</b-button
          >
          <b-collapse visible id="collapse-1" class="mt-2">
            <b-card>
              <div class="card-text">
                Initial Payment: ₹ {{ totalPayment }}
                <br />
                Refunds:
                <br />
                <ul class="refunds" :key="item.id" v-for="item in refunds">
                  <li>Refund Amount: {{ item.total }}</li>
                  <li>Reason: {{ item.reason }}</li>
                </ul>
                <br />
                Refund Total: ₹ {{ refundAggregate }}
                <br />
                Receivable Amount: ₹
                {{ parseInt(totalPayment) + refundAggregate }}
              </div>
            </b-card>
          </b-collapse>
        </div>
      </div>
      <!-- pax data comes here -->
      <div class="pax-data-table">
        <b-table
          responsive
          :items="passengers"
          :fields="fields"
          :tbody-tr-class="rowClass"
        >
          <!-- Action to remove passenger -->
          <template v-slot:cell(delete)="row">
            <checkbox
              v-model="checkboxVal"
              @input="deletePassenger(row.item)"
            />
          </template>

          <!-- Passenger Index -->
          <template v-slot:cell(index)="row">
            {{ passengers.indexOf(row.item) + 1 }}
          </template>

          <!-- Passenger Info -->
          <template v-slot:cell(name)="row">
            {{ row.item.firstname }} {{ row.item.lastname }}
            <small>{{ row.item.gender }} | {{ row.item.weight }} Kgs</small>
          </template>

          <!-- Contact Info -->
          <template v-slot:cell(phone)="row">
            <i class="uil uil-phone font-14 text-success mr-1"></i
            >{{ row.item.phone }}
            <small>{{ row.item.email }}</small>
          </template>

          <!-- DOB & Age -->
          <template v-slot:cell(dob)="row">
            {{ row.item.dob }}
            <small>Age: {{ formatAge(row.item.dob) }}</small>
          </template>

          <!-- Pilot Assignemnt -->
          <template v-slot:cell(assignment)="row">
            <pilotAssignmentDropdown
              v-model="pilotSelected"
              :pilots="pilots"
              :indiePilots="indiePilots"
              :allOperators="allOperators"
              :groupedOperatorsTable="groupedOperatorsTable"
              :flightData="getFlight(row.item)"
              :orderID="order.orderId"
              :flightPayload="flightPayload"
              :wcOrders="wcOrders"
              :pilotAssignedOnDate="pilotAssignedOnDate"
              :flightTime="order.startTime"
              :flightType="flightType"
              @input="assignPilot(row.item)"
              v-show="dataReady"
            />
          </template>
          <!-- Flight Type List -->
          <template v-slot:cell(flightType)="row">
            <flightTypeComponent
              v-model="flightTypeSelected"
              :flightData="getFlight(row.item)"
              :flightType="flightType"
              :zoneFlightTypes="zoneFlightTypes"
              @input="updateFlightType(row.item)"
            />
          </template>
        </b-table>
      </div>
    </div>
  </b-modal>
</template>

<script>
import pilotAssignmentDropdown from "@/components/subcomponents/orders/pilotAssignmentDropdown.vue";
import flightTypeComponent from "@/components/subcomponents/orders/flightTypeList.vue";
import checkbox from "@/components/subcomponents/orders/checkbox.vue";
import {
  GET_PILOTS_FOR_ASSIGNMENT,
  GET_WC_ORDERS,
  GET_PILOT_ASSIGNED_ON_DATE,
} from "@/graphql/queries/queries";
import { ADD_WC_ORDER, DELETE_WC_ORDER } from "@/graphql/mutations/mutations";
import { DateTime } from "luxon";
import _ from "lodash";
import { bus } from "@/main";

const PILOTS_ATTRIBUTE = "pilots";
const WC_ORDERS_ATTRIBUTE = "wc_orders";
const PILOT_ASSIGNED_ON_DATE_ATTRIBUTE = "flights";

export default {
  name: "orderDetailModal",
  components: {
    pilotAssignmentDropdown,
    checkbox,
    flightTypeComponent,
  },
  props: {
    order: {
      type: Object,
      default: null,
    },
    orderModalId: {
      type: String,
      default: null,
    },
    product: {
      type: Object,
      default: null,
    },
    zoneFlightTypes: {
      type: Array,
      default: null,
    },
  },

  data() {
    return {
      pilotSelected: null,
      filteredPilotList: [],
      indiePilots: [],
      allOperators: [],
      groupedOperatorsTable: [],
      flightPayload: [],
      wcOrders: [],
      pilotAssignedOnDate: [],
      to: null,
      from: null,
      validationError: "",
      dataReady: false,
      reviewMessage: "",
      checkboxVal: false,
      deletePassengerList: [],
      flightTypeSelected: null,
      fields: [
        { key: "index", label: "#", class: "pax-index" },
        { key: "name", label: "Passenger", class: "pax-data" },
        { key: "phone", label: "Contact", class: "pax-contact" },
        { key: "dob", label: "DOB", class: "pax-dob" },
        { key: "assignment", label: "Operator", class: "pilot-assignment" },
        { key: "flightType", label: "Flight Type", class: "flight-type" },
        { key: "delete", label: "Delete", class: "btn-delete" },
      ],
    };
  },
  computed: {
    orderTitle() {
      return this.order
        ? `Order #${this.order.orderId} for ${this.order.customerName} x ${this.order.quantity}`
        : "";
    },
    customerName() {
      return this.order ? this.order.customerName : "";
    },
    flightType() {
      return this.product ? this.product.type : "";
    },
    quantity() {
      return this.order ? this.order.quantity : "";
    },
    passengers() {
      return this.order ? this.order.paxData : [];
    },
    totalTax() {
      return this.order ? this.order.totalTax : "";
    },
    startTime() {
      return this.order ? this.order.startTime : "";
    },
    orderStatus() {
      return this.order ? this.order.orderStatus : "";
    },
    totalPayment() {
      return this.order ? this.order.totalPayment : "";
    },
    refunds() {
      return this.order ? this.order.refunds : 0;
    },
    refundAggregate() {
      return this.order
        ? this.order.refunds.reduce(
            (total, val) => parseInt(total) + parseInt(val.total),
            0
          )
        : 0;
    },
    changeStatus() {
      return this.order ? this.order.changeStatus : "";
    },
    bookingStatus() {
      return this.order ? this.order.bookingStatus : "";
    },
    customerEmail() {
      return this.order ? this.order.customerEmail : "";
    },
    customerPhone() {
      return this.order ? this.order.customerPhone : "";
    },
    transactionId() {
      return this.order ? this.order.transactionId : "";
    },
    pickupLoc() {
      return this.order && this.order.pickupLocation
        ? this.order.pickupLocation
        : "Press Edit to enter pickup location";
    },
    okTitleText() {
      return this.changeStatus.fullCancellation ? "Delete" : "Ok";
    },
  },
  watch: {
    order() {
      this.$apollo.queries.wcOrders.skip = false;
      this.$apollo.queries.wcOrders.refetch();
      this.filterPilots();
      this.dataReady = false; // change to false whenever order data changes or pilotAssignOnDate query runs
      // getting current date
      let startTime = this.order.startTime;
      this.from = DateTime.fromISO(startTime).toISODate();
      this.to = DateTime.fromISO(startTime).plus({ days: 1 }).toISODate();

      if (startTime) {
        this.$apollo.queries.pilotAssignedOnDate.skip = false;
        this.$apollo.queries.pilotAssignedOnDate.refetch();
      }

      // Displaying Review status message
      if (this.order.changeStatus.assigned) {
        if (this.order.changeStatus.partialCancellation) {
          this.reviewMessage = "Partial Cancellation";
        } else if (this.order.changeStatus.rescheduled) {
          this.reviewMessage = "Rescheduled";
        } else if (this.order.changeStatus.fullCancellation) {
          this.reviewMessage = "Full Cancellation";
        } else if (this.order.changeStatus.downGraded) {
          this.reviewMessage = "Flight Downgraded";
        }
      } else if (this.order.changeStatus.rescheduled) {
        this.reviewMessage = "Rescheduled";
      } else if (this.order.changeStatus.partialCancellation) {
        this.reviewMessage = "Partial Cancellation";
      } else if (this.order.changeStatus.downGraded) {
        this.reviewMessage = "Flight Downgraded";
      }
    },
    pilots() {
      this.filterPilots();
    },
    product() {
      this.filterPilots();
    },
  },
  mounted() {
    this.$root.$on("bv::modal::hidden", (modalId) => {
      if (this.orderModalId === modalId.componentId) {
        this.flightPayload = [];
        this.validationError = "";
        this.deletePassengerList = [];
        this.checkboxVal = false;
        this.reviewMessage = "";
      }
    });
  },
  methods: {
    rowClass(item, type) {
      if (!item || type !== "row") return;
      if (this.deletePassengerList.some((a) => a.passenger === item))
        return "table-danger";
    },
    deletePassenger(val) {
      if (this.checkboxVal) {
        let selectedPassenger = this.flightPayload.filter(
          (item) => item.passenger === val
        );
        this.deletePassengerList.push(...selectedPassenger);
        this.flightPayload.splice(
          this.flightPayload.findIndex((e) => e.passenger === val),
          1
        );
      } else {
        let deletedPassenger = this.deletePassengerList.filter(
          (item) => item.passenger === val
        );
        this.flightPayload.push(...deletedPassenger);
        this.deletePassengerList.splice(
          this.deletePassengerList.findIndex((e) => e.passenger === val),
          1
        );
      }
    },

    filterPilots() {
      // filter pilots by zone
      const pilotsInZone = _.filter(this.pilots, (pilot) => {
        if (this.product) {
          return pilot.operator.zone.id === this.product.zone.id;
        }
        return true;
      });
      // pilot grouping
      const groups = _.chain(pilotsInZone)
        .groupBy("operator.company_name")
        .toPairs()
        .map((pair) => _.zipObject(["operator", "operatorData"], pair))
        .value();

      // separate independent pilots in a single list
      this.indiePilots = _.chain(groups)
        .filter((d) => d.operatorData.length === 1)
        .flatMap("operatorData")
        .value();

      // all pilot list from all operators
      this.allOperators = _.chain(groups).flatMap("operatorData").value();

      // other pilots
      this.groupedOperatorsTable = _.filter(
        groups,
        (d) => d.operatorData.length > 1
      );

      // add indie pilots to this object
      this.groupedOperatorsTable.push(
        {
          operator: "All Operators",
          operatorData: this.allOperators,
        },
        {
          operator: "Independent",
          operatorData: this.indiePilots,
        }
      );
      //filter pilot list based on the flight type
      if (this.order !== null) {
        if (this.flightType.includes("Prime")) {
          this.groupedOperatorsTable = this.groupedOperatorsTable.filter(
            (a) =>
              (a.operatorData = a.operatorData.filter(
                (item) =>
                  item.pilot_type === "Prime" || item.pilot_type === "XC"
              ))
          );
        } else if (this.flightType.includes("XC")) {
          this.groupedOperatorsTable = this.groupedOperatorsTable.filter(
            (a) =>
              (a.operatorData = a.operatorData.filter(
                (item) => item.pilot_type === "XC"
              ))
          );
        }
      }
      this.groupedOperatorsTable = _.orderBy(
        this.groupedOperatorsTable,
        "operatorData",
        "desc"
      );
    },
    getFlight(rowData) {
      // find the flight associated with the current passenger.
      if (this.wcOrders.length > 0) {
        let result = this.wcOrders[0].flights.filter((a) => {
          return (
            a.passenger.dob === rowData.dob &&
            a.passenger.firstname === rowData.firstname &&
            a.passenger.lastname === rowData.lastname
          );
        });
        return result;
      }
    },
    formatDate(date) {
      return DateTime.fromISO(date).toUTC().toLocaleString(DateTime.DATE_FULL);
    },
    formatTime(date) {
      return DateTime.fromISO(date).toUTC().toFormat("h:mm a");
    },
    formatAge(dob) {
      let d1 = DateTime.local();
      let d2 = DateTime.fromISO(dob);
      let age = d1.diff(d2, ["year", "months", "days"]).toObject();
      return age.years;
    },
    assignPilot(passenger) {
      // if flight data is already present
      if (this.wcOrders.length > 0) {
        if (this.flightPayload.length !== this.wcOrders[0].flights.length) {
          if (this.deletePassengerList.length > 0) {
            let index = this.flightPayload.findIndex(
              (a) => a.passenger === passenger
            );
            if (index > -1) {
              // if the passenger name is already exist.. then updat that only and don't add
              this.flightPayload[index].pilot_id = this.pilotSelected;
            }
          } else {
            this.flightPayload.push({
              passenger: passenger,
              pilot_id: this.pilotSelected,
              flight_time: this.order.startTime,
              flight_type: this.flightType,
            });
          }
        } else if (
          this.wcOrders[0].flights.length === this.flightPayload.length
        ) {
          let index = this.flightPayload.findIndex(
            (obj) => obj.passenger === passenger
          );
          if (index > -1) {
            // if the passenger name is already exist.. then update that only and don't add
            this.flightPayload[index].pilot_id = this.pilotSelected;
          } else {
            this.flightPayload.push({
              passenger: passenger,
              pilot_id: this.pilotSelected,
              flight_time: this.order.startTime,
              flight_type: this.flightType(),
            });
          }
        }
      } else {
        // load the latest flight data
        let index = this.flightPayload.findIndex(
          (a) => a.passenger === passenger
        );
        if (index > -1) {
          // if the passenger name is already exist.. then updat that only and don't add
          this.flightPayload[index].pilot_id = this.pilotSelected;
        } else {
          this.flightPayload.push({
            passenger: passenger,
            pilot_id: this.pilotSelected,
            flight_time: this.order.startTime,
            flight_type: this.flightType,
          });
        }
      }
    },
    updateFlightType(passenger) {
      let index = this.flightPayload.findIndex(
        (a) => a.passenger === passenger
      );
      if (index > -1) {
        this.flightPayload[index].flight_type = this.flightTypeSelected;
      }
    },
    submit(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.handleSubmit();
    },
    async addMutation() {
      const addWcOrder = await this.$apollo.mutate({
        mutation: ADD_WC_ORDER,
        variables: {
          data: this.flightPayload,
          order_data: this.order,
          order_id: this.order.orderId,
          product_id: this.order.productId,
        },
      });
      if (addWcOrder) {
        this.refreshOrderData();
        console.log("added WC Order successfully", addWcOrder);
      }
    },
    checkDuplicatePassenger() {
      let result = _.uniqBy(this.flightPayload, "passenger");
      if (this.flightPayload.length === result.length) {
        return false;
      } else {
        return true;
      }
    },
    async handleSubmit() {
      try {
        if (this.flightPayload.length > this.order.paxData.length) {
          this.validationError =
            "flight Data cannot be more than no of passenger";
          this.showErrorAlert();
          console.log(
            "flight Data cannot be more than no of passenger",
            this.flightPayload
          );
        } else {
          if (this.checkDuplicatePassenger()) {
            this.validationError =
              "There cannot be same passenger with multiple pilot assigned";
            this.showErrorAlert();
            console.log(
              "duplicate passenger in the payload detected",
              this.flightPayload
            );
          } else if (this.order.quantity !== this.flightPayload.length) {
            this.validationError =
              "Number of flights does not match with order quantity";
            this.showErrorAlert();
          } else {
            // first delete the wc_order
            if (this.wcOrders.length > 0) {
              // update wc_order
              const deleteWcOrder = await this.$apollo.mutate({
                mutation: DELETE_WC_ORDER,
                variables: {
                  input: this.order.orderId,
                },
              });

              if (deleteWcOrder) {
                console.log("order deleted successfully", deleteWcOrder);
                // if its Full Cancellation then only delete the order from wc and not add
                if (!this.changeStatus.fullCancellation) {
                  // now Add wc_order
                  this.addMutation();
                }
                this.refreshOrderData();
              }
            } else {
              // Add new wc_order
              this.addMutation();
            }
          }
        }
      } catch (error) {
        console.error(error);
      }
      this.$nextTick(() => {
        this.$bvModal.hide(this.orderModalId);
      });
    },
    showErrorAlert() {
      this.$bvModal.msgBoxOk(this.validationError, {
        title: "Error",
        size: "sm",
        buttonSize: "sm",
        okVariant: "danger",
        headerClass: "p-2 border-bottom-0",
        footerClass: "p-2 border-top-0",
        centered: true,
      });
    },
    refreshOrderData() {
      bus.$emit("reloadOrder", true);
      this.$apollo.queries.wcOrders.refetch();
      this.$apollo.queries.pilots.refetch();
    },
  },
  apollo: {
    pilots: {
      // graphql query
      query: GET_PILOTS_FOR_ASSIGNMENT,
      update(data) {
        return data[PILOTS_ATTRIBUTE];
      },
      error(error) {
        const errorMessage = JSON.stringify(error.message);
        console.error(errorMessage);
      },
    },
    wcOrders: {
      query: GET_WC_ORDERS,
      variables() {
        return {
          orderID: this.order.orderId,
        };
      },
      update(data) {
        return data[WC_ORDERS_ATTRIBUTE];
        // console.log("wc orders =", data);
      },
      error(error) {
        const errorMessage = JSON.stringify(error.message);
        console.error(errorMessage);
      },
      skip: true,
    },
    pilotAssignedOnDate: {
      query: GET_PILOT_ASSIGNED_ON_DATE,
      variables() {
        return {
          from: this.from,
          to: this.to,
        };
      },
      update(data) {
        this.dataReady = true;
        return data[PILOT_ASSIGNED_ON_DATE_ATTRIBUTE];
      },
      error(error) {
        const errorMessage = JSON.stringify(error.message);
        console.error(errorMessage);
      },
      skip: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.order-details-assignment {
  .customer-info {
    padding: 24px;

    .customer-info-tab {
      div {
        span {
          font-weight: bold;
        }
      }

      i {
        vertical-align: middle;
      }

      .customer-name {
        font-size: 25px;
        text-transform: uppercase;
        margin-bottom: 0;

        span {
          vertical-align: sub;
        }
      }

      .flight-pill {
        display: inline-block;
        font-size: 11px;
        height: 25px;
        padding-right: 10px;
        font-weight: bold;
        padding-left: 3px;

        span {
          background: rgba(255, 255, 255, 0.9);
          width: 25px;
          height: 25px;
          display: inline-block;
          color: #555;
          padding: 5px 6px;
        }
      }

      .flight-pill.classic {
        background: #555;
        color: #fff;

        span {
          color: #555;
        }
      }

      .flight-pill.prime {
        background: #707af5;
        color: #fff;

        span {
          color: #707af5;
        }
      }

      .flight-pill.xc {
        background: #ffbc00;
        color: #000;

        span {
          color: #ffbc00;
        }
      }

      .customer-contact {
        .customer-phone,
        .customer-email {
          font-size: 12px;
        }
      }

      .input-group-append {
        height: 38px;
        border-radius: 0.25rem;

        button {
          border: 1px solid #ced4da;
        }
      }
    }

    .payment-info-tab {
    }
  }

  .pickup-date-time {
    span {
      vertical-align: middle;
    }
  }

  .pickup-loc {
    span {
      font-weight: bold;
      vertical-align: middle;
    }
  }

  .pax-data-table {
    ::v-deep table.table {
      color: #6c757d;

      .pax-data {
        font-weight: bold;
        text-transform: uppercase;
        min-width: 150px;

        small {
          font-weight: normal;
          display: block;
        }
      }

      th.pax-data {
        text-transform: unset;
      }

      .pax-contact {
        font-weight: bold;
        min-width: 150px;

        small {
          font-weight: normal;
          display: block;
        }
      }

      .pax-dob {
        min-width: 110px;

        small {
          display: block;
        }
      }

      .pilot-assignment {
        min-width: 160px;
      }

      .flight-type {
        min-width: 100px;
      }
    }
  }
}
</style>
