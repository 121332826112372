<template>
  <div>
    <div class="select-pilot" v-if="inputMode">
      <div class="select-assignment mb-3">
        <b-form-select v-model="selectedOperator">
          <b-form-select-option :value="null"
            >Select Operator</b-form-select-option
          >
          <template v-for="item in groupedOperatorsTable">
            <b-form-select-option :key="item.operator" :value="item.operator">
              {{ item.operator }} ({{ item.operatorData.length }})
            </b-form-select-option>
          </template>
          <!-- Instructions: Select Operator -->
          <!-- Numbers inside the brackets represent the number of pilots under that operator -->
          <!-- All Operators with only a single pilot will come under Independent -->
        </b-form-select>
      </div>
      <div class="select-assignment">
        <b-form-select v-model="selectedPilot" @input="changeState()">
          <b-form-select-option :value="null"
            >Select Pilot</b-form-select-option
          >
          <template v-for="item in filteredPilotsTable">
            <b-form-select-option :key="item.pilot_id" :value="item">
              {{ item.user.name }}
              <span>
                (C {{ item.cpy_stats_aggregate.aggregate.sum.c || 0 }}, P
                {{ item.cpy_stats_aggregate.aggregate.sum.p || 0 }}, Y
                {{ item.cpy_stats_aggregate.aggregate.sum.y || 0 }})
              </span>
            </b-form-select-option>
          </template>
          <!-- Instructions: Select Pilot -->
          <!-- Numbers inside the brackets represent the number of flights assigned to this pilot in the current month(C), previous month(P), current year(Y) -->
          <!-- All Operators with only a single pilot will come under Independent -->
          <!-- Pilots will be listed in the ascending order of the number of flights they have been assigned in the current month -->
        </b-form-select>
      </div>
    </div>
    <div class="assigned-pilot" v-else>
      <div class="assigned-pilot-data">
        <span class="assigned-pilot-name">{{ name }}</span>
        <small class="assigned-pilot-phone"
          >Ph:
          <a title="Click to dial" :href="'tel:' + phoneNo">{{
            phoneNo
          }}</a></small
        >
      </div>
      <b-button
        class="btn-edit"
        title="Edit Assignment"
        size="sm"
        variant="light"
        @click="toInputMode"
        ><i class="uil uil-edit font-18 text-secondary"></i
      ></b-button>

      <small class="assigned-pilot-exclaim" v-if="sameDay">
        <i class="uil uil-exclamation-triangle mr-2"></i>Assigned #{{
          orderNo
        }}
        on {{ dateTime }} {{ time }}
      </small>
    </div>
  </div>
</template>
<script>
import { DateTime } from "luxon";

export default {
  name: "pilotAssignmentDropdown",
  props: {
    pilots: {
      type: Array,
    },
    indiePilots: {
      type: Array,
    },
    allOperators: {
      type: Array,
    },
    groupedOperatorsTable: {
      type: Array,
    },
    flightData: {
      type: Array,
    },
    orderID: {
      type: Number,
    },
    flightPayload: {
      type: Array,
    },
    wcOrders: {
      type: Array,
    },
    pilotAssignedOnDate: {
      type: Array,
    },
    flightTime: {
      type: String,
    },
    flightType: {
      type: String,
    },
  },
  data() {
    return {
      selectedPilot: null,
      inputMode: true,
      selectedOperator: null,
      zoneTable: [],
      filteredPilotsTable: [],
      name: "",
      phoneNo: null,
      dateTime: "",
      time: "",
      defaultSelectedPilot: null,
      sameDay: false,
      orderNo: null,
    };
  },
  watch: {
    selectedOperator() {
      if (this.selectedOperator === "All Operators") {
        this.filterPilotList(this.allOperators);
      } else if (this.selectedOperator === "Independent") {
        this.filterPilotList(this.indiePilots);
      } else {
        this.filteredPilotsTable = this.pilots.filter((a) => {
          if (this.flightType.split(" ")[0] === "Prime") {
            return (
              a.operator.company_name === this.selectedOperator &&
              (a.pilot_type === "Prime" || a.pilot_type === "XC")
            );
          } else if (this.flightType.split(" ")[0] === "XC") {
            return (
              a.operator.company_name === this.selectedOperator &&
              a.pilot_type === "XC"
            );
          } else {
            return a.operator.company_name === this.selectedOperator;
          }
        });
      }
    },
    flightData() {
      if (this.flightData && this.flightData.length > 0) {
        this.selectedOperator = "All Operators";
        if (this.wcOrders[0].flights.length !== this.flightPayload.length) {
          let selectedPilotData = this.allOperators.filter(
            (a) => a.pilot_id === this.flightData[0].pilot_id
          );
          this.selectedPilot = selectedPilotData[0];
        }
      }
      this.defaultSelectedPilot = this.selectedPilot;
    },
  },
  methods: {
    filterPilotList(list) {
      this.filteredPilotsTable = list.filter((a) => {
        if (this.flightType.split(" ")[0] === "Prime") {
          return a.pilot_type === "Prime" || a.pilot_type === "XC";
        } else if (this.flightType.split(" ")[0] === "XC") {
          return a.pilot_type === "XC";
        } else {
          return a.pilot_type !== null;
        }
      });
    },
    changeState() {
      if (this.selectedPilot && this.selectedPilot.pilot_id) {
        let pilotAlreadySelected = this.flightPayload.find(
          (a) => a.pilot_id === this.selectedPilot.pilot_id
        );
        if (pilotAlreadySelected) {
          // if the selected pilot is already added to the payload
          // alert("pilot already selected");
          if (this.selectedPilot !== this.defaultSelectedPilot) {
            this.showErrorAlert();
            pilotAlreadySelected = [];
          }
        } else {
          this.$emit("input", this.selectedPilot.pilot_id);
          // check if the selected pilot is already in the wcOrders list or not
          if (this.wcOrders.length > 0) {
            let result = this.wcOrders[0].flights.find(
              (a) => a.pilot_id === this.selectedPilot.pilot_id
            );
            if (result === undefined) {
              // if not there in the wcOrder then check wether the pilot is already assigned on the same day or not
              this.checkPilotAssigned();
            }
          } else {
            this.checkPilotAssigned();
          }
          this.inputMode = false;
          this.name = this.selectedPilot.user.name;
          this.phoneNo = this.selectedPilot.user.phone_no;
        }
      }
    },
    checkPilotAssigned() {
      let result = this.pilotAssignedOnDate.find(
        (a) => a.pilot_id === this.selectedPilot.pilot_id
      );
      if (result && result.pilot_id !== null) {
        this.orderNo = result.wc_order_id;
        this.dateTime = DateTime.fromISO(result.flight_time).toFormat(
          "dd LLL yyyy"
        );
        this.time = DateTime.fromISO(result.flight_time)
          .toUTC()
          .toFormat("h:mm a");
        this.sameDay = true;
      } else {
        this.sameDay = false;
      }
    },
    toInputMode() {
      this.inputMode = true;
    },
    showErrorAlert() {
      this.$bvModal
        .msgBoxOk("pilot already selected, please assigned a different pilot", {
          title: "Error",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          headerClass: "p-2 border-bottom-0",
          footerClass: "p-2 border-top-0",
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.selectedPilot = this.defaultSelectedPilot;
          }
        })
        .catch((err) => {
          // An error occurred
          console.log(err);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.select-pilot {
  display: block;
  // Instructions: .select-pilot
  // toggle this class between "display:block" and "display:none"
  // "display: block" when pilot is not asigned
  // "display: none" when pilot has been selected
  // "display: block" when the edit button is clicked

  .select-assignment {
    display: inline-block;

    select {
      width: 260px;
    }
  }
}
.assigned-pilot {
  // Instructions: .assigned-pilot
  // toggle this class between "display: none" and "display: block"
  // "display: none" when pilot has not been assigned
  // "display: block" when pilot has been assigned
  // "display: none" when edit button is clicked to change the assignment

  .assigned-pilot-data {
    display: inline-block;
    vertical-align: middle;
  }

  .assigned-pilot-name {
    display: block;
    font-weight: bold;
    text-transform: uppercase;
  }

  .assigned-pilot-phone {
    display: block;
  }

  .btn-edit {
    display: inline-block;
    padding: 0;
    background: none;
    margin-left: 0.5em;
    border: none;
    vertical-align: top;
  }

  .assigned-pilot-exclaim {
    color: #dc3545;
    display: block;
    // default balue of this class should be "display:none"
    //"display: block" only when another flight has been assigned this pilot on this particular day
  }
}
</style>
